<template>
    <ul class="quicklinks">
        <li
            v-for="(link, index) in links"
            :key="index"
        >
            <CraftLink
                :link="link.linkField"
                appearance="button"
                class="base-button--transparent-dark"
                size="medium"
                icon="arrow-right"
                :measure-gtm-cta="true"
            />
        </li>
        <slot />
    </ul>
</template>

<script setup>
defineProps({
    links: {
        type: Array,
        default: () => []
    }
});
</script>

<style lang="less" src="./QuickLinks.less"></style>
