<template>
    <div class="panel">
        <div
            v-if="!!$slots.actions"
            class="panel__actions"
        >
            <slot name="actions" />
        </div>

        <div
            v-if="!!title || !!$slots.header"
            class="panel__header"
        >
            <slot name="header">
                <h3
                    v-if="!!title"
                    class="panel__title"
                >
                    {{ title }}
                </h3>

                <p
                    v-if="!!description"
                    class="panel__description"
                >
                    {{ description }}
                </p>
            </slot>
        </div>

        <div class="panel__body">
            <slot name="default" />
        </div>

        <div
            v-if="!!$slots.footer"
            class="panel__footer"
        >
            <slot name="footer" />
        </div>
    </div>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: ''
    },
    description: {
        type: String,
        default: ''
    }
});
</script>

<style lang="less" src="./BasePanel.less" />
